
import { ref, defineComponent } from 'vue'
import * as api from '../../utils/api'
import Menu from '../../components/menu/Hospital.vue'
import BudouX from '../../components/BudouX.vue'
import Student from '../../components/Student.vue'
import { useDialog } from 'naive-ui'

export default defineComponent({
	components: {
		Menu,
		BudouX,
		Student
	},
	data() {
		const formRef = ref(null)
		const modelRef = ref({
			text: '',
		})
		const dialog = useDialog()
		const infoDialog = (e: string, onPositiveClick: Function) => {
			const d = dialog.info({
				title: 'Confirm',
				content: e,
				closable: true,
				positiveText: 'OK',
				onPositiveClick: async () => {
					d.loading = true
					await onPositiveClick()
				},
				negativeText: 'キャンセル',
			})
		}
		return {
			formRef,
			model: modelRef,
			loading: false,
			success: false,
			totalLoading: true,
			applyId: '',
			status: 'opened',
			data: {},
			chat: [],
			id: '',
			err: '',
			infoDialog,
			rules: {
				text: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
							return value.length <= 500 || new Error('1000文字以内で入力してください')
						},
						trigger: ['input', 'blur'],
					},
				],
			},
		}
	},
	mounted: function () {
		document.title = '連絡 - Vets Index'
		this.init()
	},
	methods: {
		init: async function () {
			const fileNames = location.pathname.match(/\/([a-zA-Z0-9-]+)$/)
			if (!fileNames) return false
			const fileName = fileNames[1]
			this.id = fileName
			try {
				const data = await api.get(`/v1/hospital/chat/${fileName}`)
				this.data = data
				this.chat = data.roomData
				this.applyId = data.applyData.id
				this.status = data.status
				this.totalLoading = false
			} catch (e: any) {
				this.loading = false
				this.err = e.message || e.toString()
				this.totalLoading = false
				console.log(this.err)
				console.error(e)
			}
		},
		post: async function () {
			if (!this.model.text) return false
			if (this.model.text.length > 1000) return alert('1000文字を超過しています。')
			const param = {
				text: this.model.text,
				id: this.id,
			}
			try {
				this.success = false
				this.loading = true
				const data = await api.post('/v1/hospital/apply_send', param)
				if (data.success && !data.sameEmail) {
					this.loading = false
					this.model.text = ''
					this.init()
				} else {
					this.loading = false
					if (data.message) this.err = data.message
				}
			} catch (e: any) {
				this.loading = false
				this.err = e.message || e.toString()
				console.log(this.err)
				console.error(e)
			}
		},
		close: function (type: string) {
			const action = async (id: string) => {
				await api.post('/v1/hospital/chat/status_change', { status: type, id })
				this.$router.push('/hospital/apply')
			}
			if (type === 'closedSuccess') {
				this.infoDialog('マッチング成功としてチャットを終了します。今後この応募に関連してこの応募者にメッセージを送ることはできません。よろしいですか？', async () => await action(this.id))
			}
			if (type === 'closedFailed') {
				this.infoDialog('マッチング失敗としてチャットを終了します。今後この応募に関連してこの応募者にメッセージを送ることはできません。よろしいですか？', async () => await action(this.id))
			}
		},
		label: function (label: string) {
			if (label == 'opened') return '進行中'
			if (label == 'closedSuccess') return '成功'
			if (label == 'closedFailed') return '失敗'
		}
	},
})
